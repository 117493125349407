
import { defineComponent } from 'vue';
import Touch from '../directives/touch';
import Tabs from './Tabs.vue';

export default defineComponent({
	name: 'Tab',
	directives: {
		touch: Touch
	},
	data() {
		const isVisible: boolean = this.initialVisible;
		return {
			isVisible
		};
	},
	computed: {
		isActive: {
			// should be || this.initialActive which is set in Tabs, but seems to not work because of the inject
			get() {
				return (this.$parent as unknown as typeof Tabs)?.activeTabHash === this.hash || false;
			},
			set(newTab) {
				return newTab;
			}
		},
		direction(): string | undefined {
			return (this.$parent as unknown as typeof Tabs)?.direction || undefined;
		},
		computedId(): string {
			return this.id ? this.id : this.name.toLowerCase().replace(/ /g, '-');
		},
		hash(): string {
			if (this.isDisabled) {
				return '';
			}

			return this.computedId;
		}
	},
	props: {
		id: { type: String },
		name: { type: String, required: true },
		prefix: { type: String, default: '' },
		suffix: { type: String, default: '' },
		url: { type: String, default: undefined },
		isDisabled: { type: Boolean, default: false },
		noScroll: { type: Boolean, default: false },
		notificationBadge: { type: Boolean, default: false },
		textBadge: { type: Boolean, default: false },
		infoBadge: { type: Boolean, default: false },
		scrollToTop: { type: Boolean, default: true },
		badge: { type: [Number, String], default: '' },
		addCheckmark: { type: Boolean, default: false },
		initialActive: { type: Boolean, default: false },
		initialVisible: { type: Boolean, default: true }
	}
});
