var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tabs-component h-full"},[(_vm.headline)?_c('div',{staticClass:"text-center bg-white border-b border-color-grey-light py-3 font-bold text-lg"},[_vm._v("\n\t\t"+_vm._s(_vm.headline)+"\n\t")]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"tabs-component-tabs",class:_vm.tablistClass,attrs:{"role":"tablist"}},[_c('div',{ref:"tabs",staticClass:"xl:max-w-5xl mx-auto tabs-header-container",class:{ 'text-center': _vm.alignCenter }},_vm._l((_vm.elements),function(tab){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(tab.isVisible),expression:"tab.isVisible"}],key:_vm.computeHashForTab(tab),staticClass:"tabs-component-tab",class:{
					'is-active': tab.isActive || (_vm.nonActive && _vm.computeHashForTab(tab) === _vm.activeTabHash),
					'is-disabled': tab.isDisabled
				},attrs:{"role":"presentation"}},[_c('a',{ref:"tab",refInFor:true,class:`tabs-component-tab-a cursor-pointer ${tab.badge ? 'tabs-with-badges' : ''} ${
						_vm.smallSpacing ? 'smallspacing' : ''
					}`,attrs:{"aria-controls":_vm.computeHashForTab(tab),"aria-selected":tab.isActive || (_vm.nonActive && _vm.computeHashForTab(tab) === _vm.activeTabHash),"role":"tab"},on:{"click":function($event){_vm.selectTab(_vm.computeHashForTab(tab), $event)}}},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.computeHeaderForTab(tab))+"\n\t\t\t\t\t"),_c('client-only',[(tab.badge || tab.notificationBadge)?_c('span',{class:`badge cursor-pointer ${
								tab.notificationBadge ? 'notification-badge' : ''
							} ${tab.infoBadge ? 'info-badge' : ''} ${tab.textBadge ? 'text-badge' : ''}`,on:{"click":function($event){_vm.selectTab(_vm.computeHashForTab(tab), $event)}}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(tab && tab.badge && typeof tab.badge === 'number' && tab.badge > 9
									? '9+'
									: tab.badge)+"\n\t\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(tab.addCheckmark)?_c('HokIcon',{staticClass:"inline fill-main",attrs:{"icon":"icon-swipe-right","size":4}}):_vm._e()],1)],1)])}),0)]),_vm._v(" "),_c('div',{staticClass:"tabs-component-panels"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }