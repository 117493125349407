
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import AutocompleteExperimental from '@hokify/shared-components/lib/components/Autocomplete/AutocompleteExperimental.vue';
import LocationAutocompleteExperimental from '@hokify/shared-components/lib/components/LocationAutocomplete/LocationAutocompleteExperimental.vue';
import { fromNow } from '@hokify/shared-components/lib/helpers/datehelpers/from-now';
import { lsTest } from '@hokify/shared-components/lib/helpers/localstorage';
import { getAllAlternateUrls, getCanonicalUrl, getSocialMediaMetaData } from '~/helpers/metadata';
import HasInteracted from '~/mixins/HasInteracted';
import type { IRecentJobsearch } from '~/store/types';
import testimonialsExperimental from '~/data/testimonialsExperimental.json';
import BranchesCarousel from '~/components/website/BranchesCarousel.vue';
import ArticleCarousel from '~/components/website/ArticleCarousel.vue';
import type { IAPIArticle } from '@hokify/magazine-interface';
import AppstoreRatingsCarousel from '~/components/website/AppstoreRatingsCarousel.vue';
import DownloadsStartpage from '~/components/website/DownloadsStartpage.vue';
import CompaniesStartpage from '~/components/website/CompaniesStartpage.vue';
import SocialMediaStartPage from '~/components/website/SocialMediaStartpage.vue';
import SeoFooter, { ISeoFooterData } from '~/components/website/footer/SeoFooter.vue';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { truncateFct } from '@hokify/shared-components/lib/helpers/truncate';
import HokCarousel from '@hokify/shared-components/lib/components/HokCarousel.vue';
import { Slide } from '@hokify/hooper';

const locationStore = namespace('location');
const landingPageStore = namespace('user/landingpage');
const valuesStore = namespace('values');
const cacheStore = namespace('cache');

interface ITestimonialExperimental {
	rating: number;
	title?: string;
	text: string;
	name: string;
}

@Component({
	name: 'StartPage',
	components: {
		SeoFooter,
		AutocompleteExperimental,
		LocationAutocompleteExperimental,
		BranchesCarousel,
		AppstoreRatingsCarousel,
		DownloadsStartpage,
		CompaniesStartpage,
		SocialMediaStartPage,
		ArticleCarousel,
		HokCarousel,
		Slide
	},
	methods: { truncateFct },
	layout: 'user-startpage',
	scrollToTop: true,
	head(this: StartPage) {
		const path = this.$store.state.host;
		const canonical = getCanonicalUrl(path);
		const alternates = getAllAlternateUrls(path);
		const description = ` Auf Jobsuche? Mehr als ${this.jobsOnline} Stellenangebote im hokify Jobportal oder direkt in der App ► Finde jetzt deinen neuen Job!`;
		const result: any = {
			title: 'hokify - deine mobile Job-Plattform | Jobbörse | Stellenangebote',
			link: [canonical, ...alternates],
			meta: [
				{ hid: 'robots', name: 'robots', content: 'index, follow' },
				{
					hid: 'description',
					name: 'description',
					content: description
				}
			]
		};

		result.meta.push(
			...getSocialMediaMetaData(
				result,
				this.$store.state.topLevelDomain,
				undefined,
				undefined,
				this.$route.query
			)
		);
		return result;
	},
	async asyncData({ store, $sentry }) {
		const articleAliases = [
			'bewerbung/perfekt-vorbereitet-vorstellungsgespräch',
			'arbeitsrecht/krankmeldung-das-musst-du-beachten',
			'jobsuche/jobsuche-mit-hokify-so-einfach-gehts'
		];
		const articles: IAPIArticle[] = [];

		const rndTestimonialsExperimental: ITestimonialExperimental[] = testimonialsExperimental;
		for (let i = rndTestimonialsExperimental.length - 1; i > 0; i -= 1) {
			const j = Math.floor(Math.random() * (i + 1));
			const temp = rndTestimonialsExperimental[i];
			rndTestimonialsExperimental[i] = rndTestimonialsExperimental[j];
			rndTestimonialsExperimental[j] = temp;
		}

		try {
			await store.dispatch('user/landingpage/loadLandingPageData');
			const { articles: loadedArticles } = await store.dispatch('magazine/loadArticlesByAliases', {
				aliases: articleAliases
			});
			articles.push(...loadedArticles);
		} catch (err) {
			// ignore loadLpPageData errors
			$sentry.captureException(err);
		}
		return {
			articles,
			rndTestimonialsExperimental: rndTestimonialsExperimental.slice(0, 3)
		};
	}
})
export default class StartPage extends Mixins(HasInteracted) {
	@locationStore.Action('resolveGeoCoords') resolveGeoCoords;

	@valuesStore.Action('ontologyKeywordSuggestion') ontologyKeywordSuggestion;

	@landingPageStore.State('recentlyUpdatedJobs') recentlyUpdatedJobs;

	@landingPageStore.State('popularLandingPages') popularLandingPages;

	@landingPageStore.Getter('getMappedSpecialJobCounts') getMappedSpecialJobCounts;

	@landingPageStore.Getter('getMappedRegionalJobs') getMappedRegionalJobs;

	@landingPageStore.Getter('getMappedJobSearches') getMappedJobSearches;

	@landingPageStore.Getter('getMappedNewJobs') getMappedNewJobs;

	@landingPageStore.Getter('getMappedTopCompanies') getMappedTopCompanies;

	@cacheStore.State('marketingValues') marketingValues;

	branch = '';

	city = '';

	recentJobSearchHidden = false;

	recentJobSearches: IRecentJobsearch[] = [];

	rndTestimonialsExperimental: ITestimonialExperimental[] = [];

	articles: IAPIArticle[] = [];

	observer?: IntersectionObserver;

	showFloatingSearchbar = false;

	gsapInitialized = false;

	get mappedRecentJobsearch() {
		const recentJobSearch =
			(this.recentJobSearches?.length &&
				this.recentJobSearches[this.recentJobSearches.length - 1]) ||
			undefined;
		if (!recentJobSearch) {
			return undefined;
		}
		return {
			title: recentJobSearch.title,
			to: recentJobSearch.linkUrl
			// description: recentJobSearch.recordCount ? `${recentJobSearch.recordCount} Ergebnisse` : '',
		};
	}

	get mappedRecentJobs() {
		return this.recentlyUpdatedJobs.map(job => ({
			title: job.title,
			description: fromNow(job.recordDate),
			to: job.linkUrl
		}));
	}

	get jobsOnline() {
		if (this.marketingValues.jobsOnline > 1000) {
			return Math.floor(this.marketingValues.jobsOnline / 1000) * 1000;
		}
		if (this.marketingValues.jobsOnline > 100) {
			return Math.floor(this.marketingValues.jobsOnline / 100) * 100;
		}
		return Math.floor(this.marketingValues.jobsOnline / 10) * 10;
	}

	async mounted() {
		this.$trackWebsiteEvent('startpage_view', {
			is_logged_in: this.$store.state.login.loggedIn ? 1 : 0
		});
		// check if lastJobSearch cookie is set and load 5 associated jobs if true
		try {
			if (lsTest()) {
				// get the 5 most recent jobsearches from local storage
				const storage = localStorage.getItem('recent-jobsearches');
				if (typeof storage === 'string') {
					this.recentJobSearches = JSON.parse(storage);
				}
			}
		} catch (err) {
			this.$errorHandler.call(this, err);
		}

		this.registerIntersectionObserverFloatingSearchbar();

		if (!this.$isMobile.any) {
			this.setupDesktopGsap();
		}
	}

	async setupDesktopGsap() {
		const gsap = (await import('gsap')).default;
		gsap.registerPlugin(ScrollTrigger);

		const images = document.querySelectorAll('.mockup-image');
		const numOfTransitions = images.length;

		const singleDuration = 600;
		const totalDuration = singleDuration * numOfTransitions;

		gsap.to('.mockup-container', {
			scrollTrigger: {
				pin: '.mockup-container',
				start: 'top top+=50px',
				end: `+=${totalDuration}px`,
				pinSpacing: false
			}
		});

		images.forEach((image, i) => {
			gsap.timeline({
				scrollTrigger: {
					trigger: image,
					toggleActions: 'play reverse play reverse',
					start: `+=${singleDuration * i}px`,
					end: `+=${singleDuration}px`,
					onEnter: () => {
						gsap.to([image], { opacity: 1 });
					},
					onLeave: () => {
						if (i === images.length - 1) {
							return;
						}
						gsap.to([image], { opacity: 0 });
					},
					onEnterBack: () => {
						gsap.to([image], { opacity: 1 });
					},
					onLeaveBack: () => {
						if (i === 0) {
							return;
						}
						gsap.to([image], { opacity: 0 });
					}
				}
			});
		});

		this.gsapInitialized = true;
	}

	registerIntersectionObserverFloatingSearchbar() {
		this.observer = new IntersectionObserver(this.isVisible);
		this.observeSearchbar();
	}

	observeSearchbar() {
		let searchBar;
		if (this.$isMobile.any) {
			searchBar = document.querySelector('#jobsearch-mobile');
		} else {
			searchBar = document.querySelector('#jobsearch-desktop');
		}
		const footer = document.querySelector('#footer');
		if (searchBar) {
			this.observer?.observe(searchBar);
		}
		if (footer) {
			this.observer?.observe(footer);
		}
	}

	isVisible(entries) {
		this.showFloatingSearchbar = !entries.some(entry => entry.isIntersecting);
	}

	updateBranch(
		searchTerm: string,
		_event,
		_parameters?: { term: string; type: string; data?: { alias?: string } }
	) {
		this.branch = searchTerm;
	}

	selectBranch(
		_searchTerm: string,
		parameters?: { term: string; type: string; data?: { alias?: string } }
	) {
		if (parameters?.type === 'company' && parameters?.data?.alias) {
			this.$router.push({ path: `/c/${parameters.data.alias}` });
		}
	}

	mapSuggestionType(type: 'company' | 'keyword') {
		return type === 'company' ? 'Firma' : 'Job';
	}

	handleJobSearchExperimental() {
		this.$router.push({
			path: '/jobs/search',
			query: {
				branch: this.branch,
				city: this.city
			}
		});
	}

	get regionalJobs() {
		return this.getMappedRegionalJobs || [];
	}

	get groupedRegionalJobs() {
		const lps = [...(this.regionalJobs || [])].slice(0, 12);
		const groupedRegionalJobs: any[] = [];

		const chunkSize = 4;
		for (let i = 0; i < lps.length; i += chunkSize) {
			const chunk = lps.slice(i, i + chunkSize);
			groupedRegionalJobs.push(chunk);
		}

		return groupedRegionalJobs;
	}

	get seoFooterData(): ISeoFooterData[] {
		return [
			{
				title: 'Aktuelle Jobsuchen',
				id: 'current-jobsearches',
				searches: this.getMappedJobSearches || []
			},
			{
				title: 'Neue Jobs',
				id: 'new-jobs',
				searches: this.getMappedNewJobs || []
			},
			{
				title: 'Aktualisiert',
				id: 'updated-jobs',
				searches: this.mappedRecentJobs || []
			},
			{
				title: 'Top Firmen',
				id: 'top-companies',
				searches: this.getMappedTopCompanies || []
			},
			{
				title: 'Weitere Suchen',
				id: 'more-searches',
				searches: this.getMappedSpecialJobCounts || []
			}
		];
	}

	openSearchbarPage() {
		this.$nuxt.$loading.start();
		return import('~/components/insidepages/SearchbarPage.vue')
			.then(async searchbarComponent => {
				await this.$page.push(
					searchbarComponent,
					{},
					{
						name: 'searchbar-page',
						mode: 'modal'
					}
				);
				this.$nuxt.$loading.finish();
			})
			.catch(err => this.$errorHandler.call(this, err));
	}

	scrollToSearchbar() {
		this.$scrollTo('#startpage-hero');
		this.$trackWebsiteEvent('click_search_icon', {});
	}
}
