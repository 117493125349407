
import { defineComponent } from 'vue';

function isHTMLElement(el): el is HTMLElement {
	return el && el instanceof HTMLElement;
}

function isTabComponent(
	obj: any
): obj is { isDisabled?: boolean; isActive?: boolean; url?: string } {
	return obj && typeof obj === 'object'; // && obj.isDisabled !== undefined;
}

const stateLastSelectedTab = {};

export default defineComponent({
	name: 'Tabs',
	data() {
		const indexOfPageTabs = undefined as any;
		const recomputeElements: Date = new Date();
		return {
			recomputeElements,
			needRecomputing: false,
			direction: '',
			unsetHeight: false,
			activeTabHash: '',
			activeTabIndex: 0,
			lastActiveTabHash: '',
			blockTabChange: false,
			selectedTabIndex: -1,
			indexOfPageTabs
		};
	},
	computed: {
		nonActive(): boolean {
			return !(this.elements && this.elements.some(e => e.isActive));
		},
		elements(): {
			readonly id?: string;
			readonly name: string;
			readonly prefix?: string;
			readonly suffix?: string;
			readonly url?: string;
			readonly isDisabled?: boolean;
			readonly notificationBadge?: boolean;
			readonly infoBadge?: boolean;
			readonly textBadge?: boolean;
			readonly scrollToTop?: boolean;
			readonly badge?: [number, string];
			readonly isVisible?: boolean;
			readonly isActive?: boolean;
		}[] {
			return (
				(this.recomputeElements &&
					this.$slots.default &&
					this.$slots.default.length > 0 &&
					this.$slots.default
						.map(slot => {
							const propsData =
								(slot.componentOptions &&
									slot.componentOptions.tag === 'Tab' &&
									slot.componentOptions.propsData) ||
								((slot as any).asyncMeta &&
									(slot as any).asyncMeta.tag === 'Tab' &&
									(slot as any).asyncMeta.data.attrs);

							// now find the reactive version in $children
							const hash = this.computeHashForTab(propsData);
							const reactiveReference = (this as any).$children.find(
								child => (child as any).hash === hash
							);

							if (reactiveReference) {
								return reactiveReference;
							}

							if (process.client) {
								this.needRecomputing = true;
							}

							// no reactive version, return static one for ssr and other things
							return (
								propsData && {
									isVisible: true, // default is true
									isActive: false, // default is false
									...propsData
								}
							);
						})
						.filter(f => !!f)) ||
				[]
			);
		}
	},
	created() {
		if (this.elements && this.elements.length > 0) {
			this.startTabsView();
		}
	},
	async mounted() {
		if (!(this as any).$children || (this as any).$children.length === 0) {
			this.needRecomputing = true;
			const timeout = new Date();
			timeout.setTime(timeout.getTime() + 3 * 1000);
			const checkExist = () =>
				new Promise<void>(resolve => {
					if ((this as any).$children.length > 0) {
						resolve();
					} else if (timeout < new Date()) {
						console.error("couldn't find any tabs within 3 seconds...");
						resolve();
					} else {
						// eslint-disable-next-line no-promise-executor-return
						new Promise(resolveWait => setTimeout(resolveWait, 40)).then(() =>
							checkExist().then(resolve)
						);
					}
				});

			await checkExist();

			this.startTabsView();
		}
		if (this.needRecomputing) {
			// ensure elements are recomputed
			this.recomputeElements = new Date();
		}
	},
	updated() {
		const activeTabIndex = this.getActiveTabIndex();
		// only on mobile, set current tab in tab panel into view
		if (
			this.$isMobile.any &&
			this.$refs.tabs &&
			isHTMLElement(this.$refs.tabs) &&
			this.$refs.tab &&
			this.selectedTabIndex !== activeTabIndex
		) {
			const containerMetrics = this.$refs.tabs.getBoundingClientRect();
			const containerMetricsRight = Math.floor(containerMetrics.right);
			const containerMetricsLeft = Math.floor(containerMetrics.left);

			const contentMetrics =
				this.activeTabIndex && this.$refs.tab[this.activeTabIndex].getBoundingClientRect();
			const contentMetricsRight = Math.floor(contentMetrics.right);
			const contentMetricsLeft = Math.floor(contentMetrics.left);

			let result = -1;
			if (
				containerMetricsLeft > contentMetricsLeft &&
				containerMetricsRight < contentMetricsRight
			) {
				result = -1; // BOTH
			} else if (contentMetricsLeft < containerMetricsLeft) {
				result = this.$refs.tabs.scrollLeft + (contentMetricsLeft - containerMetricsLeft); // offset to the left
				result -= containerMetrics.width / 2 - contentMetrics.width / 2;
			} else if (contentMetricsRight > containerMetricsRight) {
				result = this.$refs.tabs.scrollLeft + (contentMetricsRight - containerMetricsRight); // offset to the right
				result += containerMetrics.width / 2 - contentMetrics.width / 2;
			} else if (activeTabIndex === 0) {
				result = 0;
			} else if (activeTabIndex === this.elements.length - 1) {
				result = containerMetrics.width;
			} else {
				result =
					this.$refs.tabs.scrollLeft + (containerMetrics.width / 2 - contentMetrics.width / 2);
			}

			if (result && this.$refs.tabs) {
				const newPos = result;
				if (typeof this.$refs.tabs.scrollTo === 'function') {
					this.$refs.tabs.scrollTo(newPos, 0);
				} else {
					this.$refs.tabs.scrollLeft = newPos;
				}
			}
			this.selectedTabIndex = this.getActiveTabIndex();
		}
	},
	methods: {
		lastSelectedTab(tabsName: string) {
			return stateLastSelectedTab[tabsName];
		},
		startTabsView() {
			if (process.client && window.location.hash && this.findTab(window.location.hash.substr(1))) {
				if (this.selectTab(window.location.hash.substr(1))) {
					return;
				}
			}

			if (this.options?.defaultTabHash && this.findTab(this.options.defaultTabHash)) {
				if (this.selectTab(this.options.defaultTabHash)) {
					return;
				}
			}

			const lastSelectedTab = this.lastSelectedTab(this.name);

			if (lastSelectedTab) {
				if (this.resetTabs) {
					if (this.selectTab(this.computeHashForTab(this.elements[0] as any))) {
						return;
					}
				} else if (this.selectTab(lastSelectedTab)) {
					return;
				}
			}

			// select first tab by default
			if (this.elements.length) {
				this.selectTab(this.computeHashForTab(this.elements[0] as any));
				return;
			}

			console.warn('tabs: no selectable tab found');
		},
		computeHeaderForTab(obj) {
			return (obj.prefix || '') + obj.name + (obj.suffix || '');
		},
		computeHashForTab(obj: { hash?: string; id?: string; name: string } | undefined) {
			if (obj && !obj.name) {
				return undefined;
			}
			return obj && (obj.hash || (obj.id ? obj.id : obj.name.toLowerCase().replace(/ /g, '-')));
		},
		setUnsetHeight(value: boolean) {
			this.unsetHeight = value;
		},
		findTab(hash: string) {
			if (this.elements.length > 0) {
				return this.elements.find(tab => this.computeHashForTab(tab) === hash);
			}

			return undefined;
		},
		goToLeft() {
			// don't register touch event if there is an open modal
			const openModal = document.querySelector('.v--modal');
			if (openModal) {
				return;
			}
			const currentIndex = this.getActiveTabIndex();
			const leftTab = this.getTabHash(currentIndex - 1);
			if (leftTab && !this.blockTabChange) {
				this.selectTab(leftTab);
			}
		},
		goToRight() {
			// don't register touch event if there is an open modal
			const openModal = document.querySelector('.v--modal');
			if (openModal) {
				return;
			}
			const currentIndex = this.getActiveTabIndex();
			const rightTab = this.getTabHash(currentIndex + 1);
			if (rightTab && !this.blockTabChange) {
				this.selectTab(rightTab);
			}
		},
		selectTab(selectedTabHash?: string, event?: Event) {
			this.blockTabChange = false;

			// See if we should store the hash in the url fragment.
			if (event) {
				event.preventDefault();
			}

			const selectedTab = selectedTabHash && this.findTab(selectedTabHash);

			if (!selectedTab) {
				return false;
			}

			if (isTabComponent(selectedTab) && selectedTab.isDisabled) {
				if (event) {
					event.preventDefault();
				}
				return false;
			}

			// console.log('this.lastActiveTabHash', this.lastActiveTabHash);
			if (this.lastActiveTabHash === this.getTabHash(selectedTab)) {
				this.$emit('clicked', { tab: selectedTab });
			} else {
				const direction = this.getTabIndex(selectedTabHash!) - this.getActiveTabIndex();

				if (direction < 0 && event) {
					this.direction = 'left';
				} else if (direction > 0 && event) {
					this.direction = 'right';
				}

				this.$emit('changed', { tab: selectedTab });
			}

			this.setActiveTab(selectedTabHash);

			// console.log('set active tab', selectedTabHash);
			this.lastActiveTabHash = this.activeTabHash;

			if (event) {
				// only if event (e.g. a click) has been provided
				stateLastSelectedTab[this.name] = this.getTabHash(selectedTab);
			}

			if (
				process.client &&
				event &&
				isTabComponent(selectedTab) &&
				selectedTab.url &&
				window.location.pathname !== selectedTab.url &&
				window.location.protocol !== 'file:'
			) {
				window.history.replaceState({}, '', selectedTab.url);
			}

			return true;
		},
		getTabIndex(hash: string): number {
			return hash ? this.elements.findIndex(t => this.computeHashForTab(t as any) === hash) : -1;
		},
		getTabHash(index) {
			const tab = this.elements[index];

			if (!tab) {
				return false;
			}

			return this.computeHashForTab(tab as any);
		},
		getActiveTabIndex() {
			return this.getTabIndex(this.activeTabHash);
		},
		setActiveTab(selectedTabHash: string | undefined) {
			if (!selectedTabHash) {
				return;
			}

			this.activeTabHash = selectedTabHash;
			this.activeTabIndex = this.getTabIndex(selectedTabHash);

			if ((this as any).$children && (this as any).$children.length > 0) {
				// reactive way, the easy part
				(this as any).$children.forEach(singleTab => {
					if (isTabComponent(singleTab)) {
						singleTab.isActive = this.computeHashForTab(singleTab as any) === selectedTabHash;
					}
				});
				return;
			}

			if (this.$slots.default && this.$slots.default.length > 0) {
				// inject property initialActive for constructor (this is necessary for SSR - but do also on client to match dom tree)
				this.$slots.default.forEach(slot => {
					if (slot.componentOptions && slot.componentOptions.propsData) {
						const singleTab = slot.componentOptions.propsData as any;
						if (this.computeHashForTab(singleTab) === selectedTabHash) {
							singleTab.initialActive = true;
						}
					}
				});
				return;
			}
			console.warn('tabs: cannot active active tab');
		},
		onTabChange(oldVal, newVal) {
			// prevent emit on initial load
			if (oldVal && newVal) {
				this.$emit('tab-changed', this.activeTabHash);
			}
		}
	},
	props: {
		options: { type: Object, required: false, default: () => ({ defaultTabHash: null }) },
		tablistClass: { type: [String, Array, Object], required: false, default: () => [] },
		name: { type: String, required: true },
		headline: { type: String },
		resetTabs: { type: Boolean, default: false },
		alignCenter: { type: Boolean, default: false },
		smallSpacing: { type: Boolean, default: false }
	},
	watch: {
		activeTabHash: [
			{
				handler: 'onTabChange'
			}
		]
	}
});
