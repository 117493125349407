
import { Component, Vue, Prop } from 'vue-property-decorator';
import Tabs from '@hokify/shared-components/lib/components/Tabs.vue';
import Tab from '@hokify/shared-components/lib/components/Tab.vue';
import { truncateFct } from '@hokify/shared-components/lib/helpers/truncate';
import { getTextForSearches } from '@hokify/shared-components/lib/helpers/jobsearch';
import type { IAPILandingPageAdditionalContent, IAPIOtherTerms } from '@hokify/common';
import type { IRecentJobsearch } from '~/store/types';

export interface ISeoFooterData {
	title: string;
	id: string;
	searches: { title: string; description: string; to: string }[];
}

@Component({
	name: 'SeoFooter',
	components: {
		Tabs,
		Tab
	},
	methods: { truncateFct }
})
export default class SeoFooter extends Vue {
	@Prop({ type: Array, default: () => [] }) readonly data!: ISeoFooterData[];

	@Prop({ type: Boolean, default: true }) readonly showTabView!: boolean;

	@Prop({
		type: Array,
		default: () => []
	})
	readonly recentJobSearches!: IRecentJobsearch[];

	@Prop({
		type: Object,
		default: () => {}
	})
	readonly otherTerms!: IAPIOtherTerms;

	@Prop({
		type: Array,
		default: () => []
	})
	readonly popularLandingPages!: IAPILandingPageAdditionalContent[];

	getTextForSearches = getTextForSearches;

	get availableCategories() {
		return this.data.filter(category => category.searches.length);
	}
}
